import "./NavBar.css";
import {NavLink} from "react-router-dom";
import {useUserContext} from "../../../context/userContext/context";
import {useEffect} from "react";
import Loader from "../loader/Loader";

const NavBar = () => {

    const {user, userLoading, getUserData, logoutUser} = useUserContext()
    useEffect(() => {
        getUserData()
    }, [])
    console.log(user)
    if (userLoading) return <nav><Loader/></nav>
    if (!user || user.needAuth) return <nav>
        <NavLink to={'/login'}
                 className={({isActive, isPending}) =>
                     isPending ? "navLink" : isActive ? "navLink active" : "navLink"
                 }>
            Login
        </NavLink>
    </nav>

    return <nav>
        <NavLink
            to={'/inbox'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
            Inbox
        </NavLink>

        <NavLink
            to={'/controlPanel'}
            className={({isActive, isPending}) =>
                isPending ? "navLink" : isActive ? "navLink active" : "navLink"
            }>
            Control Panel
        </NavLink>
        <NavLink
            to={'/login'}> <div className={'navLink'} onClick={logoutUser}>Logout</div>
        </NavLink>
    </nav>;
};
export default NavBar;
